import React, { useEffect } from "react";

export default function DynamicsFormPage3() {
    useEffect(loadDynamics, []);
    function loadDynamics() {
      const script = document.createElement('script');
      script.src = "https://cxppusa1formui01cdnsa01-endpoint.azureedge.net/oce/FormLoader/FormLoader.bundle.js";
      script.async = true;
      document.body.insertBefore(script, document.body.childNodes[0]);      
    }
  
    return (
      <div        
        data-form-id="6d7b6b7c-f617-ef11-840a-0022481207cb"
        data-form-api-url="https://public-oce.mkt.dynamics.com/api/v1.0/orgs/19fff07f-37e7-ee11-904a-0022489423ce/landingpageforms"
        data-cached-form-url="https://assets-oce.mkt.dynamics.com/19fff07f-37e7-ee11-904a-0022489423ce/digitalassets/forms/6d7b6b7c-f617-ef11-840a-0022481207cb"
      />
    );
  }